<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <b-link class="brand-logo">
                <h2 class="brand-text text-primary ml-1">
                    <b-img :src="logo_img" width="60" />
                </h2>
            </b-link>
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5" >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                    fluid
                    :src="imgUrl"
                    alt="Login V2"
                    />
                </div>
            </b-col>
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">

                    <b-card-title
                        title-tag="h4"
                        class="font-weight-bold mb-1 text-primary"
                    >
                        {{$t('Create your store and launch into the world of e-commerce')}}! 🚀
                    </b-card-title>
                    <validation-observer ref="loginValidation">
                    <b-form
                    class="auth-login-form mt-2"
                    @submit.prevent="submit"
                    >
                        <b-form-group
                        :label="$t('Store Name')"
                        label-for="register-store-name"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('Store Name')"
                                rules="required"
                            >
                                <b-form-input
                                    id="register-store-name"
                                    v-model="form.store_name"
                                    :state="errors.length > 0 ? false:null"
                                    name="register-store-name"
                                    placeholder=""
                                />
                                <small class="text-danger">{{ store_name_msg }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group
                        :label="$t('Store Url')"
                        label-for="register-store-url"
                        :description="$t('You can later change the url to your own url')"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('Store Url')"
                                rules="required"
                            >
                                <b-input-group>
                                    <b-form-input
                                        id="register-store-url"
                                        v-model="form.url"
                                        :state="errors.length > 0 ? false:null"
                                        name="register-store-url"
                                        placeholder=""
                                        dir="ltr"
                                    />
                                    <b-input-group-append is-text dir="ltr">
                                        mhd.sa/
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ url_msg }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group
                        :label="$t('Store Owner Name')"
                        label-for="register-owner-name"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('Store Owner Name')"
                                rules="required"
                            >
                                <b-form-input
                                    id="register-owner-name"
                                    v-model="form.name"
                                    :state="errors.length > 0 ? false:null"
                                    name="register-owner-name"
                                    placeholder=""
                                />
                                <small class="text-danger">{{ name_msg }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group
                        :label="$t('Email')"
                        label-for="register-email"
                        >
                            <validation-provider
                            #default="{ errors }"
                            :name="$t('Email')"
                            rules="required|email"
                            >
                            <b-form-input
                            id="register-email"
                            v-model="form.email"
                            :state="errors.length > 0 ? false:null"
                            name="register-email"
                            placeholder=""
                            />
                            <small class="text-danger">{{ email_msg }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group
                        :label="$t('Mobile')"
                        label-for="register-mobile"
                        :description="$t('Ex: 9665xxxxxxxx')"
                        >
                            <b-form-input
                            id="register-mobile"
                            v-model="form.mobile"
                            name="register-mobile"
                            placeholder="9665xxxxxxxx"
                            />
                            <small class="text-danger">{{ mobile_msg }}</small>
                        </b-form-group>
                        <b-form-group>
                            <div class="d-flex justify-content-between">
                                <label for="register-password">{{$t('Password')}}</label>
                                <b-link :to="{name:'login'}">
                                    <small>{{$t('Forgot Password?')}}</small>
                                </b-link>
                            </div>
                            <validation-provider
                            #default="{ errors }"
                            :name="$t('Password')"
                            rules="required"
                            >
                                <b-input-group
                                class="input-group-merge"
                                :class="errors.length > 0 ? 'is-invalid':null"
                                >
                                    <b-form-input
                                    id="register-password"
                                    type="password"
                                    v-model="form.password"
                                    :state="errors.length > 0 ? false:null"
                                    class="form-control-merge"
                                    name="register-password"
                                    placeholder="············"
                                    />
                                </b-input-group>
                                <small class="text-danger">{{ password_msg }}</small>
                            </validation-provider>
                        </b-form-group>
                        <!-- <b-form-group>
                            <b-form-checkbox
                            id="remember-me"
                            name="checkbox-1"
                            >
                                Remember Me
                            </b-form-checkbox>
                        </b-form-group> -->
                        <b-button
                        type="submit"
                        variant="primary"
                        block
                        >
                            {{$t('Create Store')}}
                            <template v-if="isLoading">
                                <b-spinner small />
                            </template>
                        </b-button>
                    </b-form>
                    </validation-observer>
                    <b-card-text class="text-center mt-2">
                        <span>{{$t('Already registered?')}} </span>
                        <b-link :to="{name:'login'}">
                            <span>&nbsp;{{$t('Sign in')}}</span>
                        </b-link>
                    </b-card-text>
                </b-col>
            </b-col>
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {mapActions,mapGetters} from 'vuex'
import useJwt from '@/auth/useJwt'


import store from '@/store/'

export default {
  components: {
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  name:'Register',
  data() {
    return {
        sideImg: require('@/assets/images/pages/register-v22.png'),
        form:{
            store_name:'',
            url:'',
            name:'',
            email:'',
            mobile:'',
            password:'',
        },
        store_name_msg:'',
        url_msg:'',
        name_msg:'',
        email_msg:'',
        mobile_msg:'',
        password_msg:'',
        showError:false,
        logo_img: require('@/assets/images/logo/logo.png'),
        isLoading: false,
    }
  },
  mounted() {
    localize('ar',{
      messages: {
        required: 'هذا الحقل مطلوب',
        email: 'يرجى إدخال بريد إلكتروني صحيح',
      },
    })
    this.$i18n.locale = 'ar'
  },
  computed: {
  passwordToggleIcon() {
    return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
  },
  imgUrl() {
    if (store.state.appConfig.layout.skin === 'dark') {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.sideImg = require('@/assets/images/pages/register-v22.png')
      return this.sideImg
    }
    return this.sideImg
  },
  ...mapGetters({errors:"getError"}),
},
  methods: {
    ...mapActions(["register"]),

    submit: function () {
        // this.$store.dispatch('register', this.form)
        // .then(() => this.$router.push('/'))
        // .catch(err => {this.showError = true;})
        this.isLoading = true;
        useJwt.post('/create-store',this.form)
        .then(response => {
            useJwt.setToken(response.data.data.token)
            useJwt.setUserData(JSON.stringify(response.data.data.user))
            useJwt.setStoreData(JSON.stringify(response.data.data.store))
            window.location.href = '/';
        })
        .catch(error => {
            //console.log(error.response);
            this.isLoading = false;
            if(error.response.data.message.store_name != undefined) {
                this.store_name_msg = error.response.data.message.store_name[0]
            } else {
                this.store_name_msg =  ''
            }
            if(error.response.data.message.url != undefined) {
                this.url_msg = error.response.data.message.url[0]
            } else {
                this.url_msg =  ''
            }
            if(error.response.data.message.name != undefined) {
                this.name_msg = error.response.data.message.name[0]
            } else {
                this.name_msg =  ''
            }
            if(error.response.data.message.email != undefined) {
                this.email_msg = error.response.data.message.email[0]
            } else {
                this.email_msg =  ''
            }
            if(error.response.data.message.mobile != undefined) {
                this.mobile_msg = error.response.data.message.mobile[0]
            } else {
                this.mobile_msg =  ''
            }
            if(error.response.data.message.password != undefined) {
                this.password_msg = error.response.data.message.password[0]
            } else {
                this.password_msg =  ''
            }
        })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
