var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_c('b-img',{attrs:{"src":_vm.logo_img,"width":"60"}})],1)]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1 text-primary",attrs:{"title-tag":"h4"}},[_vm._v(" "+_vm._s(_vm.$t('Create your store and launch into the world of e-commerce'))+"! 🚀 ")]),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('Store Name'),"label-for":"register-store-name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Store Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-store-name","state":errors.length > 0 ? false:null,"name":"register-store-name","placeholder":""},model:{value:(_vm.form.store_name),callback:function ($$v) {_vm.$set(_vm.form, "store_name", $$v)},expression:"form.store_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.store_name_msg))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Store Url'),"label-for":"register-store-url","description":_vm.$t('You can later change the url to your own url')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Store Url'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"register-store-url","state":errors.length > 0 ? false:null,"name":"register-store-url","placeholder":"","dir":"ltr"},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}}),_c('b-input-group-append',{attrs:{"is-text":"","dir":"ltr"}},[_vm._v(" mhd.sa/ ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.url_msg))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Store Owner Name'),"label-for":"register-owner-name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Store Owner Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-owner-name","state":errors.length > 0 ? false:null,"name":"register-owner-name","placeholder":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.name_msg))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","state":errors.length > 0 ? false:null,"name":"register-email","placeholder":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.email_msg))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Mobile'),"label-for":"register-mobile","description":_vm.$t('Ex: 9665xxxxxxxx')}},[_c('b-form-input',{attrs:{"id":"register-mobile","name":"register-mobile","placeholder":"9665xxxxxxxx"},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.mobile_msg))])],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"register-password"}},[_vm._v(_vm._s(_vm.$t('Password')))]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('small',[_vm._v(_vm._s(_vm.$t('Forgot Password?')))])])],1),_c('validation-provider',{attrs:{"name":_vm.$t('Password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":"password","state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.password_msg))])]}}])})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" "+_vm._s(_vm.$t('Create Store'))+" "),(_vm.isLoading)?[_c('b-spinner',{attrs:{"small":""}})]:_vm._e()],2)],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('Already registered?'))+" ")]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Sign in')))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }